._3rlMlGTdkaXdFX8V6b1Ko0 {
    padding-left: 10px;
    padding-top: 20px;
    justify-content: center;
}

._1cquKLDjIZM6po__paAXcJ{
    margin-top: 14px;
}

._3Dku9qtd2Imacyy26iujAN .ant-page-header{
    padding: 0;
}

._3JDFMeI5h-d_2lgjExMNX3 .anticon{
    font-size: 25px;
    vertical-align: middle;
}

._3qsCrS1lUKrj9Z-7UmrLf8 {
    justify-content: center;
    margin-bottom: 5px;
}

._3dFfpQflftUoDXUPa5syBA {
    font-weight: bold;
    display: inline-block;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}
