._3xDmT6upI8gh0o8F_tdd2 {
    padding-left: 80px;
    padding-top: 20px;
}

.G3v-O4buStJn746GCH1gK{
    padding: 20px;
}

._3Rb5zrNNU1fRiR5_Ilw1d-{
    cursor: pointer;
}

._3q4KQdbL-HDI54ek6gk1ut .ant-card{
    position: relative;
    border-radius: .5rem;
    min-height: 300px;
    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07);
}

._3q4KQdbL-HDI54ek6gk1ut .ant-btn{
    position: absolute;
    bottom: 20px;
    right: 20px;
}
