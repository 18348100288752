._3llKI820g3DgBxibuVMbDi {
    min-height: 100vh;
    background: white;
    padding-top: 0;
}

.ncrmGtTj8YQBTXwz3gf_N {
    height: 35px;
    margin: 10px 24px 16px 0;
    float: left;
}

.ncrmGtTj8YQBTXwz3gf_N img {
    width: 120px;
    padding-bottom: 24px;
}

._1INbdzYWvMRRFwJVvWiN6f {
    background: white;
    z-index: 1;
    margin-top: 1.5%;
    padding: 0;
}

@media only screen and (min-width: 768px) {
    ._1INbdzYWvMRRFwJVvWiN6f {
        background: white;
        z-index: 1;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 1.5%;
        padding: 0;
    }
}

.p5rypLarcboBhzpPVSxUh {
    z-index: 1;
    text-align : center;
    margin-top: 60px;
}

._3SpwgnCfODW2nk8YlV99_F {
    margin-left: 5px;
}

._3cgr0IVGCKxIEuB8VQOxN9 {
    margin-bottom: 10px;
    float: right;
}

._2NVBA3SsI57e23Mq_Ujo_ {
    margin-right: 20px;
}
